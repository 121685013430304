// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-components-templates-staff-index-js": () => import("./../../../src/components/templates/Staff/index.js" /* webpackChunkName: "component---src-components-templates-staff-index-js" */),
  "component---src-pages-404-index-js": () => import("./../../../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-class-prep-index-js": () => import("./../../../src/pages/class-prep/index.js" /* webpackChunkName: "component---src-pages-class-prep-index-js" */),
  "component---src-pages-college-consulting-index-js": () => import("./../../../src/pages/college-consulting/index.js" /* webpackChunkName: "component---src-pages-college-consulting-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-online-index-js": () => import("./../../../src/pages/online/index.js" /* webpackChunkName: "component---src-pages-online-index-js" */),
  "component---src-pages-sat-index-js": () => import("./../../../src/pages/SAT/index.js" /* webpackChunkName: "component---src-pages-sat-index-js" */),
  "component---src-pages-schedule-index-js": () => import("./../../../src/pages/schedule/index.js" /* webpackChunkName: "component---src-pages-schedule-index-js" */),
  "component---src-pages-testimonials-index-js": () => import("./../../../src/pages/testimonials/index.js" /* webpackChunkName: "component---src-pages-testimonials-index-js" */)
}

